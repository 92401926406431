.header-container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 0px 8px -4px #000000;
    background: #6271f0;

    z-index: 3; /* Ensure the header is above other content */
    position: fixed;
    top: 0;
    left: 0;



    height: calc(var(--initial-window-height) / 10);

  }


  
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
  }
  
  /* also a button */
  .logo-container {
    display: flex;
    align-items: flex-end;
    color: white;

    cursor: pointer;
  }

  .logo-container:hover {
    /* color: rgb(1, 38, 54); */
    /* color: #35a853; */
    color: #9ab8ff;

  }
  
  .logo-text {
    padding-left: 10px;
    font-size: 3em;
    font-family: 'Kefa';
  }

  .cakeIcon {
    /* margin-right: 10px; */
    /* color: #4caf50; */
    align-self: center;
  }
  
  .header-bar-misc-buttons-container,
  .social-banner-container {
    display: flex;
    align-items: center;
  }
  
  .hamburger-button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #6271f0;
    position: absolute;
    top: 60px;
    right: 15px;
    box-shadow: 0px 0px 8px -4px #000000;
    padding: 10px;
    width: calc(100% - 30px); /* Ensure full width minus padding */
  }
  
  .hamburger-menu > * {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .hamburger-menu-icon {
    font-size: 20px;
    /* Additional styling if needed */
  }
  
  .hamburger-menu-icon {
    font-size: 30px; /* Larger size */
    color: white;    /* White color */
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .header-box {
      flex-direction: row;
    }
  }
  