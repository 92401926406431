.header-bar-misc-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .header-bar-misc-buttons.hamburger {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .join-our-team-button-container,
  .get-quote-button-container {
    margin-left: 10px;
  }
  
  /* Add margin-right to spread out buttons */
  .join-our-team-button-container {
    margin-right: 20px; /* Adjust as needed for spacing */
    cursor: pointer;
  }
  
  .header-bar-misc-buttons.hamburger .join-our-team-button-container,
  .header-bar-misc-buttons.hamburger .get-quote-button-container {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .join-our-team-text {
    font-family: 'Kefa';
    text-decoration: underline;
    color: white;
  }
  .join-our-team-text:hover {
    font-family: 'Kefa';
    text-decoration: underline;
    color: rgb(1, 38, 54);
  }
  
  
  .button-inside-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-text-container {
    font-family: 'Kefa';
    font-weight: 100;
    font-size: 1.25em;
  }
  