.teamSection {
    text-align: center;
    /* padding: 50px 20px; */
    padding-top: 10%;
    /* background: #ffffff; */
    background: linear-gradient(#FF4A59, #6271f0);
    height: 100%;
    width: 100%;

  }
  
  .heading {
    font-size: 2.5em;
    /* margin-top: 5%; */
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 10px; Add some space between the icon and text */
  }
  
  .icon {
    font-size: 3em;
    color: #ffffff;
    margin: 1%;
  }

  
  
  .description {
    color: #ffffff;
    font-size: 1.75em;
    margin: 1%;
    margin-bottom: 5%;

  }
  

  
  .slider {
    width: 100%;
    /* .slick-track {
      display: flex;
    }
    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 10px;
    } */
  }
  
  .slick-slide {
    /*padding: 0 10px;*/ /*Add padding between slides */
    padding: 0 15px; /* Add padding between slides */
    box-sizing: border-box; /* Include padding in width calculation */
  }