


.section {
  flex: 1 0 100vh; /* Ensure each section takes up full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  padding: 20px;
  box-sizing: border-box;
}
