.social-icon-banner {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  
  .button-icon-container {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .social-icon {
    color: white;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: black;
  }
  
  /* Styles specific to hamburger menu */
  .social-icon-banner.hamburger {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .social-icon-banner.hamburger .button-icon-container {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  