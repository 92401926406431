@import './Layout.module.css'; /*not currently used */
/* Define other global styles here */

/* Ensure the background color is consistent */
body, html, .App {
  background-color: #6271f0; /* Updated color */
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Kefa', sans-serif;
  display: flex;
  flex-direction: column;

  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  transition: all 0.3s ease-in-out;


  flex-grow: 1;

}


.section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex: 1 0 100vh; /* Ensure each section takes up full viewport height */
}

.intro-primary {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.intro-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  justify-content: center;


}

/* Fun picture styles */
.intro-right {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;
  z-index: 0; 
  /*overflow: hidden; */
  margin-left: 5%;
}

/* .corporate-art-image {
  width: 100%;
  height: auto;
}  */

/* Company ticker styles */
.intro-footer{

  /*width: 100%; 
  z-index: 2;*/
}


/* Intro message styles */
/* .intro-message {
  grid-column: 1 / 5;
  grid-row: 2 / 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0 0 0 15%;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  z-index: 1; 
}

.intro-message h1 {
  font-size: 6.5em;
  line-height: 0.9em;
  margin: 0;
}

.intro-message p {
  padding-top: 1em;
  font-size: 1.75em;
} */



/* Fun picture styles */
/* .fun-picture {
  grid-column: 5 / 9;
  grid-row: 1 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 0; 
  overflow: hidden; 
}

.corporate-art-image {
  width: 100%;
  height: auto;
} */

/* Quote entry point styles */
/* .quote-entry-point {
  grid-column: 2 / 5;
  grid-row: 6 / 8; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 2em;
}

.quote-button {
  background-color: #35a853;
  color: white;
  border: none;
  padding: 20px 40px;
  font-size: 2em;
  cursor: pointer;
  border-radius: 10px;
}

.quote-button:hover {
  background-color: #2e8b47;
}

.quote-text {
  margin-top: 0.5em;
  font-size: 1.5em;
  color: white;
} */

/* Company ticker styles */
/* .company-ticker {
  grid-column: 1 / 9;
  grid-row: 9 / 10;
  width: 100%; 
  z-index: 2;
} */

/* Project submission styles */

.project-submission {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  

}

.project-submission-primary {
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:safe;

  /* background-color: #191e48;  */
  /* background-color: #0f122c;  */
  background: linear-gradient(0.25turn, #121533, #000000, #000000);

  /* background: linear-gradient(#6271f0,#000000); */
}

.project-submission-left {
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 50%;
  z-index: 0; 
  /*overflow: hidden; */
  /* margin-left: 5%; */
  /* padding-top: 3%;
  margin-left: -7.5%; */
  

}

.project-submission-right {

  width: 50%; 
  /*overflow: hidden; */
  /* margin-left: -10%; */
  /* display: flex;
  flex-direction: column; */

  /* padding-right: 5%;
  padding-left: 5%; */
  /* margin-right: 7%;
  margin-left: 7%; */
  margin: 3%;
  /* padding: 5%; */
  padding-right: 5%;
}

.project-art-image {
  width: 80%;

}



/* Responsive design for mobile */
@media (max-width: 768px) {
  :root {
    --row-height-mobile: calc(var(--initial-window-height) / 20); /* Adjust the number of rows for mobile view */
  }

  .main-content {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(20, var(--row-height-mobile));
    height: auto;
  }

  .intro-message {
    grid-column: 1 / 4;
    grid-row: 3 / 5;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
  }

  .intro-message h1 {
    font-size: 4em;
  }

  .intro-message p {
    padding-top: 1em;
    font-size: 1.1em;
  }

  .fun-picture {
    grid-column: 4 / 7;
    grid-row: 2 / 6;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide the overflow */
  }

  .corporate-art-image {
    width: 125%;
    height: auto;
  }

  .quote-entry-point {
    grid-column: 2 / 6;
    grid-row: 7 / 9;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  .company-ticker {
    grid-column: 1 / 9;
    grid-row: 10 / 11; /* Adjusted to be part of the main content grid */
    width: 100%;
  }

  .project-submission {
    grid-column: 1 / 7;
    grid-row: 11 / 20; /* Adjusted to span rows within project submission */
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, var(--row-height-mobile)); /* Consistent with the number of rows for mobile view */
    height: auto;
  }

  .project-image {
    grid-column: 1 / 7;
    grid-row: 11 / 16;
  }

  .project-form {
    grid-column: 1 / 7;
    grid-row: 12 / 20;
  }

  .project-form h2 {
    grid-column: 1 / 7;
  }

  .checkboxes {
    grid-template-columns: repeat(3, 1fr);
    grid-column: 1 / 7;
    grid-row: 13 / 14;
  }

  .email-input,
  .idea-input {
    grid-column: 1 / 7;
    grid-row: 16 / 18;
  }

  .send-button {
    grid-column: 2 / 5;
    grid-row: 19 / 20;
  }
}
