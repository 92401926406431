.quoteEntryPoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
   /*margin-top: 2em;*/ /* Adjust margin to create space */

    margin: 5%;
  }
  
  .quoteButton {
    background-color: #35a853;
    color: white;
    border: none;
    padding: 20px 40px;
    font-size: 2em;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .quoteButton:hover {
    background-color: #2e8b47;
  }
  
  .quoteText {
    margin-top: 0.5em; /* Adjusted margin */
    font-size: 1.5em;
    color: white;
  }
  