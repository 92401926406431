.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    /* background: #fdf3e7; */
    /* background: #d4e0ff; */
    background: linear-gradient(0.25turn, #2b306a, #121533, #121533);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 80%;
    max-width: 800px;

    padding: 40px;
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #6c63ff;
    color: white;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.2); /* Indented shadow effect */
  }
  
  .closeButton:hover {
    background: #5348c3;
  }
  