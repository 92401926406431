.introMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 0 0 0 15%;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    z-index: 1; /* Ensure it stays in front of the fun picture */
    
    margin: 5%;
  }
  
  .introMessage h1 {
    font-size: 6.5em;
    line-height: 0.9em;
    margin: 0;
  }
  
  .introMessage p {
    padding-top: 1em;
    font-size: 1.75em;
  }
  