/*.company-ticker {
    height: 100%;
    width: 100%;
}
*/
.transition {
    opacity: 0;
}
  
.transition-enter {
    opacity: 0;
}
  
.transition-enter-active {
    opacity: 1;
    transition: all 500ms;
}
  
.transition-exit {
    opacity: 1;
}
  
.transition-exit-active {
    opacity: 0;
    transition: all 750ms;
}
