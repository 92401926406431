.card {
    /* background: #191e4b;  */
    background: #6271f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    text-align: center;
    padding: 7.5%;
    margin: 7.5%;

    background: linear-gradient(#000000, #6271f0);

    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /*width: 21em;*/ /* Adjust the width to make the cards thinner */
   

    flex-shrink: 0; /* Prevent cards from shrinking */
    /* border: 0.25em solid #ffffff; */
    /* border: 0.5em solid #6498ff; */
    box-sizing: border-box; /* Include padding and border in width calculation */

  }


  
  .image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 15px;
    /* border: 0.25em solid #ffffff; */
    border: 0.5em solid #6498ff;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.75);
  }

  .name {
    color: #ffffff;
  }
  
  .role {
    color: #ffffff;
    font-size: 1.25em;
  }

  
  .socialLinks {
    display: flex;
    justify-content: center;
    gap: 10px;
    /* margin-top: 10px; */
  }
  
