.funPicture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /*overflow: hidden; */
    z-index: 0; /* Ensure it stays behind the header */
  }
  
  .corporateArtImage {
    width: 100%;
    height: auto;
    /*overflow: hidden;*/
  }
  