.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .header {
    font-size: 3em;
    color: #6c63ff;
    margin-bottom: 20px;
  }
  
  .text {
    font-size: 1.75em;
    color: #6c63ff;
    margin-bottom: 30px;
  }
  
  .image {
    width: 50%;
    height: auto;
    margin-bottom: 30px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  
  .cakeIcon {
    margin-right: 10px;
    margin-bottom: 3%;
    /* color: #4caf50; */
    color: #6c63ff;
  }
  
  .companyName {
    font-size: 2em;
    /* color: #4caf50; */
    color: #6c63ff;
  }
  