.projectForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    height: 100%;

    z-index: 1; /* Ensure it stays in front of the image */
    min-height: min-content;
    /* padding-top: 5%;
    padding-right: 5%; */

  }
  
  .projectForm h2 {
    color: white;
    /* color: #ffed88; */
    /* padding-top: 10%; */
    /* margin-bottom: -5%; */
    font-size: 3.5em;
  }

  .projectForm h3 {
    color: white;
    /* color: #ffed88; */
    /* padding-top: 10%; */
    margin-top: -7%;
    font-size: 2em;

  }
  
  .checkboxes {
    /* padding-top: 1%;
    padding-bottom: 1%; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25em;
    color: white;
    align-items: space-between;
    text-align: left;
    justify-content: flex-end;
    height: 10%;
    width: 100%;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
  }
  
  .checkboxLabel input {
    margin-right: 1em; /* Adjust the spacing between checkbox and text */
  }
  
  .checkbox {
    -webkit-appearance: none; /* Remove default checkbox styling */
    -moz-appearance: none; /* Remove default checkbox styling */
    appearance: none; /* Remove default checkbox styling */
    width: 20px;
    height: 20px;
    /* border: 1px solid #6498ff; */
    /* border: 1px solid #ffed88; */

    border-radius: 4px;
    /* outline: 0.15em solid #6498ff; */
    /* outline: 0.15em solid #ffed88; */

    background-color: white;
    position: relative;
  }
  
  .checkbox:checked::before {
    content: ''; /* Remove the default checkmark */
    display: block;
    width: 100%;
    height: 100%;
    /*background-color: #4fc56f;*/ /* Custom background color when checked */
    background-color: #bc3131;

    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .textBoxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    width: 100%;
  }
  
  .emailInput,
  .ideaInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 0.6em;
    /* border: 0.25em solid #6498ff; */
    /* border: 0.25em solid #ffed88; */
    border: 0px solid #6498ff;
  }
  
  .ideaInput {
    height: 100px;
  }

  .emailInput:focus,
  .ideaInput:focus {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 0.6em;
    /* border: 0.25em solid #6498ff; */
    /* border: 0.25em solid #ffed88; */
    /* border: 0.25em solid #bc3131; */
    /*remove shadow / heighlight */
    /* outline:none; */
    outline: 0.25em solid #bc3131;

  }
  
  
  .sendButton {
    background-color: #FF4A59;
    color: white;
    border: none;
    font-size: 2em;
    cursor: pointer;
    border-radius: 10px;
    /* margin: 0; */
    /* margin-top: -5%; */
    /* margin-bottom: 3%; */
    padding: 20px 60px; /* Increased padding to avoid clipping */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Adjust spacing between icon and text */
  }
  
  .sendButton:hover {
    background-color: #fd6370;
  }
  